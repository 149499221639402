import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/es7.symbol.async-iterator";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/es7.symbol.async-iterator";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/es7.symbol.async-iterator";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";

function _toConsumableArray(arr) {
  return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread();
}

function _nonIterableSpread() {
  throw new TypeError("Invalid attempt to spread non-iterable instance");
}

function _iterableToArray(iter) {
  if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter);
}

function _arrayWithoutHoles(arr) {
  if (Array.isArray(arr)) {
    for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) arr2[i] = arr[i];

    return arr2;
  }
}

import React from 'react';
import { GenericTemplate } from '@walltowall/gatsby-theme-ww-prismic';
import { slicesMap, mapDataToPropsEnhancer } from 'src/slices/PageBody';
import { SpeedBump } from 'src/components/SpeedBump';
export const slicesMiddleware = (list = []) => [{
  __typename: 'PageBodyHeader',
  id: 'header'
}].concat(_toConsumableArray(list), [{
  __typename: 'PageBodyFooter',
  id: 'footer'
}]);
export const PageTemplate = props => {
  return React.createElement(React.Fragment, null, React.createElement(GenericTemplate, Object.assign({
    customType: "page",
    sliceZoneId: "body",
    slicesMap: slicesMap,
    slicesMiddleware: slicesMiddleware,
    mapDataToPropsEnhancer: mapDataToPropsEnhancer
  }, props)), React.createElement(SpeedBump, null));
};
export default PageTemplate;
export const query = "3299501229";